<template>
<div>
    <div class="dispatchGrid">

        <PageHeader :title="`Dispatch - ${weekString}`">
            <div class="actionBtns">
                <TruckUpdates :yearWeek="filter.yearWeek"/>
                <button class="default simple _icon" v-if="isUserPermitted('loads', 'view')" @click="showCarriersModal = true">Carriers<i class="fas fa-truck-moving"></i></button>
                <router-link to="/app/search" class="default simple _icon" v-if="isUserPermitted('loads', 'view')" @click="showSearchModal = true"><i class="fas fa-search"></i></router-link>
                <button class="default simple _icon" @click="creditCheckModal = true">Credit check <i class="fa-solid fa-check-to-slot"></i></button>
                <button class="default simple _icon" v-if="isUserPermitted('loads', 'view')" @click="$store.state.loadManager.addLoad = true">Add load<i class="fas fa-plus"></i></button>
            </div>
        </PageHeader>

        <div class="wrapper">
            <Stats :stats="loads.weeklyStats" :target="loads.target" :yearWeek="filter.yearWeek"/>
        </div>
        

        <div class="wrapper loadsGrid">

            <div class="listHeader">
                <h2>Loads • {{ filterLoadsByDispatcher ? filterLoadsByDispatcher.length : 0 }}</h2>
                <div class="sortOptions">

                    <div class="weekPickerGroupHolder">
                        <button class="weekChangeButton" @click="switchWeekBy(-1)"><i class="fa-solid fa-arrow-left"></i></button>
                        <div class="weekPicker" :class="{ 'active' : showWeekPicker }" v-tooltip="'Show loads by week'" v-click-outside="() => { showWeekPicker = false }">
                            <button class="weekPickerSelected" @click="showWeekPicker = !showWeekPicker">
                                <span class="calendar"><i class="fa-solid fa-calendar-days"></i></span>
                                <span>{{ yearkWeekString }}</span>
                                <span><i class="fa-solid fa-caret-down"></i></span>
                            </button>
                            <div class="weekPickerHolder" :class="{ 'active' : showWeekPicker }">
                                <WeekPickerCalendar @select="showWeekPicker = false" v-model="filter.yearWeek"/>
                            </div>
                        </div>
                        <button class="weekChangeButton" @click="switchWeekBy(1)"><i class="fa-solid fa-arrow-right"></i></button>
                    </div>
                    
                    <DispatchSelector  v-model="sort.dispatcher" :list="loads.listOfDispatchers"/>
                    <CarrierSelector v-tooltip="'Show loads by carrier'" v-model="sort.carrier" :list="loads.listOfCarriers"/>
                    <ObjDropDown :key="filter.yearWeek" v-tooltip="'Show loads by day'" class="daySelection" :options="weekDays" v-model="filter.date"/>
                </div>
            </div>


            <div class="incompleteLoadsWarning" v-if="_incompleteLoadsCount > 0">
                <div class="incompleteLoadsWarningContent">
                    <div class="warning-icon"><i class="fa-solid fa-triangle-exclamation"></i></div>
                    <div class="warning-text">It appears that some of your loads are incomplete. Please upload the required documents and mark them as delivered.</div>
                </div>
                <button @click="_showIncompleteLoads = true" class="default small round">View loads</button>
            </div>
            <ModalSimple v-model="_showIncompleteLoads" title="Incomplete Loads" size="large" @confirm="() => { _showIncompleteLoads = false }" confirm="Got it!" :loading="isLoading(['incompleteLoads_UploadPOD', 'incomplete_loads_getListOfIncompleteLoads', 'incompleteLoads_MarkAsDelivered'])">
                <IncompleteLoadsView :loads="_incompleteLoads" @refresh="_incomplete_loads_get"/>
            </ModalSimple>
            
            <div class="listOfLoads" v-if="filterLoadsByDispatcher && filterLoadsByDispatcher.length > 0">
                
                <div class="searchForm">
                    <input type="text" class="searchInput" placeholder="Search by Load #, Origin or Destination" v-model="sort.searchKeyword"/>
                </div>

                <div class="load header">
                    <div>#</div>
                    <div>Date</div>
                    <div>Carrier</div>
                    <div>Origin</div>
                    <div>Destination</div>
                    <div>Rate</div>
                    <div>Broker</div>
                    <div>Weight</div>
                    <div>Status</div>
                    <div>Dispatcher</div>
                    <div>
                        
                        <div class="itemSelectorDropDown">
                            <div class="name textNoWrap">Driver: <a href="" @click.prevent>{{ sort.driver ? sort.driver : 'All' }}</a></div>
                            <select class="headerDropDown" v-model="sort.driver">
                                <option :value="null">All drivers</option>
                                <option :key="key" :value="driver" v-for="(driver, key) of distinctDrivers">{{ driver }}</option>
                            </select>
                        </div>

                    </div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div>
                    <Load class="load" :key="item._id" v-for="item of filteredLoadsByDriver" :load="item" @dblclick="viewLoad(item)" @view="viewLoad"/>
                </div>
            </div>

             <div class="noLoadsMessage" v-else>
                <div class="_icon"><i class="fas fa-truck-loading"></i></div>
                <div class="_text">Oops, looks like you have no loads for this week.</div>
            </div>

        </div>

    </div>

     <!-- Carriers list in the modal -->
    <Modal v-model="showCarriersModal" title="Carriers">
        <Carriers/>
    </Modal>

    <ModalSimple v-model="creditCheckModal" title="Run credit check" :loading="isLoading(['mcCheck'])" :hideButtons="true">
        <CreditCheck/>
    </ModalSimple>

</div>

</template>

<script>
import { mapGetters } from 'vuex';
import Load from '../../components/dispatch/Load.vue'
import Stats from '../../components/dispatch/Stats.vue'
import YearWeekSelector from '../../components/dispatch/YearWeekSelector.vue'
import TruckUpdates from '../../components/dispatch/TruckUpdates.vue'
import Carriers from '../../components/dispatch/Carriers.vue'
import DispatchSelector from '../../components/dispatch/DispatchSelector.vue'
import CarrierSelector from '../../components/dispatch/CarrierSelector.vue'
import CreditCheck from '../../components/dispatch/CreditCheck.vue'
import moment from 'moment';

import IncompleteLoads from '../../mixins/dispatch/IncompleteLoads';
import IncompleteLoadsView from '../../components/dispatch/incompleteLoads/LoadsView.vue'

    export default {
        mixins: [IncompleteLoads],
        components: {
            Load,
            Stats,
            YearWeekSelector,
            TruckUpdates,
            Carriers,
            DispatchSelector,
            CarrierSelector,
            CreditCheck,
            IncompleteLoadsView
        },
        data() {
            return {
                loads: {},
                filter: {
                    yearWeek: null,
                    date: 'All',
                },
                sort: {
                    carrier: '',
                    dispatcher: '',
                    searchKeyword: '',
                    driver: null
                },
                showCarriersModal: false,
                creditCheckModal: false,
                showWeekPicker: false,
                weekDays: {
                    "All": { text: 'Full week', value: '' },
                    "Monday": { text: 'Monday', value: '' },
                    "Tuesday": { text: 'Tuesday', value: '' },
                    "Wednesday": { text: 'Wednesday', value: '' },
                    "Thursday": { text: 'Thursday', value: '' },
                    "Friday": { text: 'Friday', value: '' },
                    "Saturday": { text: 'Saturday', value: '' },
                    "Sunday": { text: 'Sunday', value: '' },
                }
            }
        },
        computed: {
            ...mapGetters(['webSocket']),
            weekString() {
                if(!this.filter.yearWeek) return '';
                let date = moment.utc(this.filter.yearWeek, "YYYYWW");
                return 'Week ' + date.isoWeek();
            },
            yearkWeekString() {
                if(!this.filter.yearWeek) return 'Not selected';
                let date = moment.utc(this.filter.yearWeek, "YYYYWW");
                let endOfWeek = date.clone().endOf('isoWeek');
                return date.format("D MMM") + " - " + endOfWeek.format("D MMM");
            },
            filterLoadsByCarrier() {
                if(!this.loads || !this.loads.list) return [];
                let id = this.sort.carrier;
                return this.loads.list.filter(load => load.carrier.includes(id));
            },
            filterLoadsByDispatcher() {
                let id = this.sort.dispatcher;
                let search = this.sort.searchKeyword.toLowerCase();
                if(id == 'All') return this.filterLoadsByCarrier;
                return this.filterLoadsByCarrier.filter(load => load.dispatcher.includes(id) || load.sharingWith && load.sharingWith.includes(id));
            },
            filteredLoadsByKeyword() {
                let search = this.sort.searchKeyword.toLowerCase();
                return this.filterLoadsByDispatcher.filter(item => {
                    let loadID = item.loadID.toString();
                    let origin = item.origin.toLowerCase();
                    let dest = item.destination.toLowerCase();

                    return loadID.includes(search) || origin.includes(search) || dest.includes(search);
                });
            },
            distinctDrivers() {
                let drivers = new Set();
                this.filteredLoadsByKeyword.map(item => {
                    drivers.add(item.displayDriverName);
                });
                return Array.from(drivers).sort();
            },
            filteredLoadsByDriver() {
                let driver = this.sort.driver;
                if(!driver) driver = "";
                return this.filteredLoadsByKeyword.filter(item => {
                    return item.displayDriverName.includes(driver);
                });
            },
        },
        methods: {
            switchWeekBy(val = 1) {
                if(!this.filter.yearWeek) return;
                let date = moment.utc(this.filter.yearWeek, "YYYYWW");
                date = date.add(val, 'weeks');
                this.filter.yearWeek = date.year() + "W" + date.isoWeek();               
            },
            getLoads() {
                let params = new URLSearchParams(this.filter).toString();

                this.ajax('getListOfLoads', {
                    method: 'GET',
                    url: `/dispatch/getLoads?${params}`,
                }, (err, body) => {
                    if(err) {
                        return;
                    }
                    this.loads = body;
                });
            },
            viewLoad(load) {
                this.$store.state.loadManager.viewLoad = load._id;
            },
            webSocketListeners() {
                if(!this.webSocket) return;
                
                this.webSocket.on('targetUpdate', (body) => {
                    if(this.loads && this.loads.target && body.yearWeek === this.filter.yearWeek) {
                        this.loads.target = body.target;
                    }
                });

                this.webSocket.on('weeklyStatsUpdate', (body) => {
                    if(this.loads && this.loads.weeklyStats && body.stats.yearWeek === this.filter.yearWeek) {
                        this.loads.weeklyStats = body.stats;
                    }
                });

                this.webSocket.on('loadsUpdated', (body) => {
                    if(body.yearWeek && body.yearWeek === this.filter.yearWeek) {
                        this.getLoads();
                    }
                });
            }
        },
        mounted() {
            this.getLoads();
            this.webSocketListeners();
        },
        watch: {
            webSocket() {
                this.webSocketListeners();
            },
            filter: {
                deep: true,
                handler() {
                    this.getLoads();
                }
            },
            sort: {
                deep: true,
                handler() {
                    if(this.sort.driver && !this.distinctDrivers.includes(this.sort.driver)) this.sort.driver = null;
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.dispatchGrid {
    display: grid;
    row-gap: 50px;
}

.incompleteLoadsWarning {
    padding: 10px 20px;
    border-left: 2px solid $error;
    background: $bgSecondary;
    border-radius: 5px;
    font-weight: 500;
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: space-between;
    box-shadow: $boxShadow;
    .incompleteLoadsWarningContent {
        display: grid;
        grid-template-columns: auto minmax(0, 1fr);
        align-items: center;
        align-content: center;
        gap: 10px;
        .warning-icon {
            color: $error;
        }
    }
}

.weekPicker {
    position: relative;
    z-index: 2;
    &.active {
        z-index: 999;
    }
    .weekPickerSelected {
        height: 40px;
        border: 0;
        border-radius: $borderRadius;
        background: $bgSecondary;
        box-shadow: $boxShadow;
        padding: 0 20px;
        display: grid;
        grid-template-columns: auto auto auto;
        column-gap: 20px;
        align-items: center;
        font-weight: bold;
        color: $buttonColor;
    }
    .weekPickerHolder {
        position: absolute;
        top: 100%;
        right: 0;
        width: 400px;
        box-shadow: $boxShadow;
        border: 1px solid $borderColor;
        border-radius: $borderRadius;
        overflow: hidden;
        transform: translateY(40px);
        opacity: 0;
        pointer-events: none;
        transition: ease 0.3s;
        &.active {
            opacity: 1;
            pointer-events: auto;
            transform: translateY(20px);
        }
    }
}


.listOfLoads {
    border-radius: $borderRadius;
    background: $bgSecondary;
    padding: 20px;
    box-shadow: $boxShadow;
    .load {
        display: grid;
        grid-template-columns: 50px 100px minmax(0, 1fr) 150px 150px 75px minmax(0, 1fr) 75px 120px minmax(0, 1fr) minmax(0, 1fr) 20px 20px 20px;
        height: 40px;
        align-items: center;
        padding: 5px 5px;
        column-gap: 10px;

        &:nth-child(2n) {
            background: $bg;
        }
        &:last-child {
            border-radius: 0 0 $borderRadius $borderRadius;
        }
        &.header {
            font-weight: 500;
            border-radius: $borderRadius;
            background: $buttonColor2;
            height: 50px;
        }
    }
}

.loadsGrid {
    display: grid;
    row-gap: 30px;
}


.listHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .sortOptions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 20px;
        .daySelection {
            box-shadow: $boxShadow;
        }
    }
}


.noLoadsMessage {
    width: 100%;
    padding: 100px 0;
    display: grid;
    row-gap: 30px;
    justify-content: center;
    justify-items: center;
    ._icon {
        font-size: 50px;
    }
    ._text {
        font-size: 14px;
    }
}

.actionBtns {
    margin-left: auto;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(5, auto);
    column-gap: 20px;
}


.searchForm {
    display: grid;
    row-gap: 20px;
    margin-bottom: 20px;
    .searchInput {
        width: 100%;
        height: 50px;
        border: 1px solid $borderColor;
        border-radius: $borderRadius;
        background: transparent;
        padding: 0 20px;
    }
}

.itemSelectorDropDown {
    position: relative;
    width: 100%;
    height: 100%;
    .headerDropDown {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: 0;
    }
    .name {
        background: inherit;
        position: static;
        z-index: 2;
    }
}

.headerDropDown {
    border: 0;
    background: transparent;
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    cursor: pointer;
    width: 100%;
    padding: 0;
    margin: 0;
}

.weekPickerGroupHolder {
    display: grid;
    grid-template-columns: 40px auto 40px;
    .weekChangeButton {
        border: 0;
        background: transparent;
        color: $buttonColor;
    }
}
</style>