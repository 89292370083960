<template>
    <div>

        <div v-if="selected">
            <Edit :showBackBtn="true" @back="closeCarrierDetails" :carrier="selected" :disabled="true"/>
        </div>

        <div class="listOfItems" v-else>
            <div class="search">
                <input type="text" v-model="search" placeholder="Search for carriers">
            </div>
            <div class="item" :key="key" v-for="(item, key) of searchCarriers">
                <div class="name"><a href="" @click.prevent="selectCarrier(item)">{{ item.name }}</a></div>
                <div class="mc">{{ item.mcNumber }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import Edit from '../carriers/Edit'

    export default {
        components: {
            Edit
        },
        data() {
            return {
                carriers: [],
                search: '',
                selected: null
            }
        },
        methods: {
            selectCarrier(item) {
                this.selected = item;
            },
            closeCarrierDetails() {
                this.selected = null;
            },
            getListOfCarriers() {
                this.$axios.get('/dispatchCarriers')
                .then(data => {
                    this.carriers = data.data;
                });
            }
        },
        computed: {
            searchCarriers() {
                let search = this.search.toLowerCase();
                return this.carriers.filter(item => {
                    let name = item.name.toLowerCase();
                    return name.includes(search);
                });
            }
        },
        mounted() {
            this.getListOfCarriers();
        }
    }
</script>

<style lang="scss" scoped>
.listOfItems {
    border-radius: $borderRadius;
    border: 1px solid $borderColor;
    background: $bgSecondary;
    overflow: hidden;
    .search {
        border-bottom: 1px solid $borderColor;
        height: 50px;
        input {
            width: 100%;
            height: 100%;
            border: 0;
            padding: 0 20px;
        }
    }
    .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        padding: 0 20px;
        &:nth-child(2n) {
            background: $bg;
        }
    }
}
</style>