<template>
    <div class="mcCheckerHolder">

        <div class="formHolder">
            <Input v-model="mc" name="MC #" @keyup.enter="check" placeholder="MC"/>
            <Input v-model="loadValue"  @keyup.enter="check" name="Load value" placeholder="Load value"/>
        </div>

        <div class="results" v-if="results">

            <div class="resultsBlock">
                <h2>Triumph</h2>
                <div v-if="results['Triumph'] && !results['Triumph'].error" class="triumphDetails">

                    <div class="approvedStatus">
                        <div class="status triumph" :class="{ 'approved' : results['Triumph'].approved }">
                            <span v-if="results['Triumph'].approved"><i class="fa-solid fa-check"></i></span>
                            <span v-else><i class="fa-solid fa-xmark"></i></span>
                        </div>
                    </div>
                    <div class="message">{{ results['Triumph'].message }}</div>

                    <div class="littleDetails">

                        <div class="details" v-if="results['Triumph'].docketNumber">
                            <div class="name">Docket number:</div>
                            <div class="value">{{ results['Triumph'].docketNumber }}</div>
                        </div>
                        
                        <div class="details" v-if="results['Triumph'].name">
                            <div class="name">Name:</div>
                            <div class="value">{{ results['Triumph'].name }}</div>
                        </div>

                        <div class="details" v-if="results['Triumph'].city">
                            <div class="name">City:</div>
                            <div class="value">{{ results['Triumph'].city }}, {{ results['Triumph'].state }}</div>
                        </div>

                        <div class="details" v-if="results['Triumph'].phone">
                            <div class="name">Phone:</div>
                            <div class="value">{{ results['Triumph'].phone }}</div>
                        </div>

                        <div class="details" v-if="results['Triumph'].averageDaysToPay">
                            <div class="name">Average Days to Pay:</div>
                            <div class="value">{{ results['Triumph'].averageDaysToPay }}</div>
                        </div>

                        <div class="details" v-if="results['Triumph'].numberOfInvoices">
                            <div class="name">Number of invoices (90 days):</div>
                            <div class="value">{{ results['Triumph'].numberOfInvoices }}</div>
                        </div>
                    </div>

                </div>
                <div v-else>
                    <div class="noResultsMessage">No results found</div>
                </div>
            </div>

            <div class="resultsBlock">
                <h2>RTS</h2>
                <div v-if="results['RTS'] && results['RTS'].length > 0" class="rtsItems">
                    <div :key="key" v-for="(item, key) of results['RTS']" class="rtsItem">
                        <div class="status" :class="{ 'approved' : ['A', 'B', 'C'].includes(item.rating) }">
                            <span v-if="['A', 'B', 'C'].includes(item.rating)"><i class="fa-solid fa-check"></i></span>
                            <span v-else><i class="fa-solid fa-xmark"></i></span>
                        </div>
                        <div class="itemContent">
                            <div class="name">{{ item.name }}</div>
                            <div class="description">
                                {{ item.mcNumber }} • Rating: {{ item.rating }}
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="noResultsMessage">No results found</div>
                </div>
            </div>
        </div>
        
        <div class="buttonHolder">
            <button class="default medium" @click="check">Check</button>
        </div>

    </div>
</template>

<script>
    export default {
        data() {
            return {
                mc: '',
                loadValue: '4000',
                results: null
            }
        },
        methods: {
            check() {
                this.results = null;
                this.ajax('mcCheck', {
                    url: `https://mc-checker-by-hradovyy.herokuapp.com/check/${this.mc}/${this.loadValue}`,
                    method: 'GET'
                }, (err, body) => {
                    this.results = body;
                });
            }
        },
    }
</script>

<style lang="scss" scoped>

.noResultsMessage {
    padding: 20px;
    text-align: center;
}


.rtsItems {
    display: grid;
    row-gap: 10px;
}

.status {
    width: 44px;
    height: 44px;
    background: #eee;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F08080;
    color: #fff;
    font-size: 20px;
    &.approved {
        background: #1ABC9C;
    }
    &.triumph {
        width: 64px;
        height: 64px;
        font-size: 30px;
    }
}

.rtsItem {
    background: $bgSecondary;
    padding: 10px;
    box-shadow: $boxShadow;
    border-radius: $borderRadius;
    display: grid;
    grid-template-columns: 44px minmax(0, 1fr);
    column-gap: 20px;
    .itemContent {
        display: grid;
        row-gap: 5px;
        .name {
            font-weight: bold;
        }
    }
}

.formHolder {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 20px;
}

.approvedStatus {
    font-size: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.resultsBlock {
    display: grid;
    row-gap: 30px;
}

.triumphDetails {
    display: grid;
    row-gap: 30px;
    .message {
        text-align: center;
    }
    .littleDetails {
        display: grid;
        row-gap: 10px;
    }
}

h2 {
    text-align: center;
}

.details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .value {
        text-align: right;
    }
}

.buttonHolder {
    display: flex;
    justify-content: flex-end;
}

.results {
    display: grid;
    // grid-template-columns: repeat(2, minmax(0, 1fr));
    row-gap: 30px;
    align-items: start;
}

.mcCheckerHolder {
    display: grid;
    row-gap: 20px;
}
</style>