<template>
    <div v-if="user.team" class="carrierList">
        <div :key="key" v-for="(carrier, key) of user.team.carriers">
            <CarrierTruckUpdate v-model="updates[carrier]" :carrier="carrier"/>
        </div>
    </div>
    <div v-else>
        You are not assigned to any team yet
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CarrierTruckUpdate from './CarrierTruckUpdate.vue'

    export default {
        props: ['yearWeek', 'loadedUpdates'],
        components: {
            CarrierTruckUpdate
        },
        data() {
            return {
                updates: this.loadedUpdates || {}
            }
        },
        computed: {
            ...mapGetters(['carriers', 'user']),
            listOfTeamCarriers() {
                if(!this.user.team) return [];
                let carriers = [];
                for(const item of this.user.team.carriers) {
                    for(const carrier of this.carriers) {
                        if(item === carrier._id) {
                            carriers.push(carrier);
                        }
                    }
                }
                return carriers;
            }
        },
        methods: {
            handleSubmit() {
                this.$axios.post('/updates', {
                    yearWeek: this.yearWeek,
                    team: this.user.team._id,
                    update: this.updates
                })
                .then(data => {
                    console.log(data.data);
                })
                .catch(err => {
                    alert("Something went wrong");
                });
            }
        },
        watch: {
            loadedUpdates: {
                deep: true,
                handler() {
                    this.updates = this.loadedUpdates;
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.carrierList {
    display: grid;
    row-gap: 20px;
}
</style>