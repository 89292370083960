<template>
    <div class="quickEditContentHolder">

        <div class="formHolder">
            
            <div class="input">
                <div class="_header">
                    <label>Driver</label>
                </div>
                <select v-model="selectedDriver._id">
                    <option :value="null">-- No Driver --</option>
                    <option :key="key" :value="driver._id" v-for="(driver, key) of drivers">{{ driver.name }}</option>
                </select>            
            </div>

        </div>

        <div class="actionButtons">
            <button class="default medium" @click="$emit('close', { driver: selectedDriver })">Save changes</button>
        </div>

    </div>
</template>

<script>
    export default {
        props: ['options'],
        data() {
            return {
                body: this.options,
                selectedDriver: { _id: null },
                errors: {},
                drivers: []
            }
        },
        mounted() {
            this.getListOfDrivers();
            if(this.body.hasOwnProperty('driver')) {
                this.selectedDriver = this.body.driver || { _id: null };
            }
        },
        watch: {
            "selectedDriver._id"() {
                for(const item of this.drivers) {
                    if(item._id === this.selectedDriver._id) {
                        this.selectedDriver = JSON.parse(JSON.stringify(item));
                        break;
                    }
                }
            }
        },
        methods: {
            getListOfDrivers() {
                this.drivers = [];
                if(!this.body.carrier) return;
                this.ajax('GetListOfDrivers', {
                    method: 'GET',
                    url: `/carrier/drivers/${this.body.carrier}`,
                }, (err, body) => {
                    if(err) return;
                    if(body) {
                        this.drivers = body;
                    }
                });
            }
        },
    }
</script>

<style lang="scss" scoped>
.quickEditContentHolder {
    display: grid;
    row-gap: 30px;
    .actionButtons {
        display: flex;
        justify-content: center;
    }
}

.formHolder {
    display: grid;
    row-gap: 15px;
}
</style>