<template>
    <div>

        <div class="chartGroup">
            <div class="title">
                <div class="name">Team stats</div>
            </div>


            <div class="chartHolder">

                <div class="histoChart">
                    <div class="histo" :key="key" v-for="(item, key) in stats.teams">
                        <div class="details">
                            <div class="name textNoWrap">{{ item.name }}</div>
                            <div class="stats">${{ priceConverter(item.gross) }}</div>
                        </div>
                        <div class="barStats" v-if="stats.teamGrossLeader && stats.teamGrossLeader.gross && stats.teamCountLeader && stats.teamCountLeader.count">
                            <div class="bar" v-tooltip="`$${ priceConverter(item.gross) }`" :style="`width: ${(item.gross * 100) / stats.teamGrossLeader.gross}%`"></div>
                            <div class="bar loadsNum" v-tooltip="`${item.count} loads`" :style="`width: ${(item.count * 100) / stats.teamCountLeader.count}%`"></div>
                        </div>
                    </div>
                </div>

            </div>


        </div>

    </div>
</template>

<script>
import { mapGetters } from 'vuex'
    export default {
        props: ['stats'],
        data() {
            return {
                
            }
        },
        computed: {
            ...mapGetters(['carriers']),
            carriersObj() {
                let list = {};
                for(const item of this.carriers) {
                    list[item._id] = item;
                }
                return list;
            }
        }
    }
</script>

<style lang="scss" scoped>

.chartGroup {
    background: $bgSecondary;
    border-radius: $borderRadius;
    box-shadow: $boxShadow;
    border: 1px solid $borderColor;
    overflow: hidden;
    .title {
        border-bottom: 1px solid $borderColor;
        height: 54px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        .name {
            font-weight: bold;
            font-size: 14px;
            text-transform: uppercase;
        }
    }
}


.chartHolder {
    background: $bg;
}

.histoChart {
    .histo {
        width: 100%;
        height: 100%;
        display: grid;
        align-items: center;
        grid-template-columns: 200px auto;
        column-gap: 20px;
        position: relative;
        padding: 10px 20px;
        &:nth-child(2n) {
            background: $bgSecondary;
        }
        .barStats {
            display: grid;
            row-gap: 5px;
        }
        .bar {
            background: $success;
            width: 0%;
            height: 10px;
            transition: 1s ease;
            transition-delay: 0.5s;
            border-radius: 3px;
            &.loadsNum {
                background: $buttonColor;
                // background: #F7DC6F;
            }
        }
        .details {
            display: grid;
            row-gap: 5px;
            border-right: 1px solid $borderColor;
            .name {
                font-weight: bold;
                padding: 0 20px 0 0;
            }
        }
    }
}
</style>