<template>
    <div class="item" v-if="carrierDetails">
        <div class="name">
            <div class="value">{{ carrierDetails.name }}</div>
            <button class="default small round reverse" @click.prevent="addRow">Add</button>
        </div>
        <div class="trucks">
            
            <div v-if="updates && updates.length > 0">
                <div class="truckFormGroup" :key="key" v-for="(item, key) of updates">
                    <button class="removeBtn" @click="removeItem(key)"><i class="fas fa-times"></i></button>

                    <div class="truckUpdateFormGrid">
                        <div class="trucksForm triple">
                            <QuantitySelector v-tooltip="`Number of available trucks`" v-model="item.numOfAll" min="1" max="99"/>
                            <div v-tooltip="`Trucks location`"><input class="formInput" type="text" v-model="item.location" placeholder="Location"></div>
                            <QuantitySelector v-tooltip="`Number of covered trucks`" v-model="item.numOfCovered" min="0" :max="item.numOfAll"/>
                            <div v-tooltip="`Note`" style="height: 34px"><input type="text" class="formInput" v-model="item.note" placeholder="Note"></div>
                        </div>
                        <div v-if="item.numOfAll === 1">
                            <a href="" class="driverButton" @click.prevent="addDriverToUpdate(item)">
                                <span><i class="fa-regular fa-id-card"></i></span>
                                <span>{{ item.driver && item.driver._id ? `${item.driver.name} ${item.driver.lastname}` : 'Add driver' }}</span>
                            </a>
                        </div>
                    </div>

                    
                </div>
            </div>

            <div class="noTrucksMessage" v-else>
                No trucks added yet
            </div>
            
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import QuantitySelector from '../QuantitySelector.vue'
import DriverPicker from './Updates/DriverPicker.vue'

    export default {
        components: {
            QuantitySelector
        },
        props: ['carrier', 'modelValue'],
        data() {
            return {
                numOfAll: 1,
                numOfCovered: 0,
                location: '',
                updates: this.modelValue || []
            }
        },
        methods: {
            addDriverToUpdate(item) {
                this.$showModalSimple(DriverPicker, 'Select Driver', {
                    carrier: this.carrier,
                    driver: item.driver
                }, (data) => {
                    if(data && data.hasOwnProperty('driver')) item.driver = data.driver;
                });
            },
            addRow() {
                this.updates = this.updates || [];
                this.updates.push({
                    numOfAll: 1,
                    numOfCovered: 0,
                    location: '',
                    note: ''
                });
            },
            removeItem(key) {
                this.updates.splice(key, 1);
            },
            isValidUpdate(item) {
                let pattern = /^\d+$/;
                return pattern.test(item.numOfAll) && pattern.test(item.numOfCovered) && Number(item.numOfCovered) <= Number(item.numOfAll);
            }
        },
        computed: {
            ...mapGetters(['carriers']),
            carrierDetails() {
                for(const item of this.carriers) {
                    if(item._id === this.carrier) return item;
                }
                return null;
            },
            isValid() {
                let pattern = /^\d+$/;
                return pattern.test(this.numOfAll) && pattern.test(this.numOfCovered) && this.numOfCovered <= this.numOfAll;
            }
        },
        watch: {
            updates: {
                deep: true,
                handler() {
                    this.$emit('update:modelValue', this.updates);
                }
            },
            modelValue() {
                this.updates = this.modelValue;
            }
        }
    }
</script>

<style lang="scss" scoped>
.item {
    display: grid;
    row-gap: 10px;
    .name {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .value {
            font-size: 16px;
            font-weight: 500;
        }
    }
}

.noTrucksMessage {
    padding: 20px;
    text-align: center;
}


.trucks {
    border: 1px solid $borderColor;
    display: grid;
    border-radius: $borderRadius;
}

.driverButton {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: start;
    justify-items: start;
    align-items: center;
    column-gap: 10px;
}

.truckFormGroup {
    display: grid;
    row-gap: 10px;
    border-bottom: 1px solid $borderColor;
    position: relative;
    .removeBtn {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(50%, -50%);
        opacity: 0;
        pointer-events: none;
        width: 24px;
        height: 24px;
        border: 0;
        background: $error;
        color: #fff;
        border-radius: 50%;
        transition: ease 0.5s;
        font-size: 10px;
    }
    &:hover {
        .removeBtn {
            opacity: 1;
            pointer-events: auto;
        }
    }
    &:last-child {
        border: 0;
    }
}

.formInput {
    border: 1px solid $borderColor;
    text-align: center;
    border-radius: $borderRadius;
    background: $bgSecondary;
    padding: 0 10px;
    width: 100%;
    height: 100%;
}


.truckUpdateFormGrid {
    display: grid;
    padding: 10px;
    row-gap: 20px;
}

.trucksForm {
    display: grid;
    grid-template-columns: 50px minmax(0, 1fr) 50px 50px;
    grid-template-rows: 34px;
    column-gap: 10px;
    &.triple {
        grid-template-columns: auto minmax(0, 1fr);
        row-gap: 10px;
        grid-template-rows: 34px 34px;
    }
    button {
        height: auto;
        padding: 0;
    }
}

.validUpdate {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>