<template>
    <div style="max-width: 762px; width: 100%;">
        <div class="loads-grid">
            
            <Load :load="load" :key="load" v-for="load of loads" @podUploaded="$emit('refresh')" @statusChanged="$emit('refresh')"/>
            
        </div>
    </div>
</template>

<script>
import Load from './Load.vue'
    export default {
        components: {
            Load
        },
        props: ['loads']
    }
</script>

<style lang="scss" scoped>
.loads-grid {
    display: grid;
    gap: 20px;
}
</style>