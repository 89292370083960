<template>
    <div class="quantitySelector">
        <button type="button" @click="decrease"><i class="fas fa-minus"></i></button>
        <div class="number">{{ selected }}</div>
        <button type="button" @click="increase"><i class="fas fa-plus"></i></button>
    </div>
</template>

<script>
    export default {
        props: ['modelValue', 'max', 'min'],
        data() {
            return {
                selected: this.modelValue,
                maxNum: this.max || 999,
                minNum: this.min || 0,
            }
        },
        methods: {
            decrease() {
                if(this.selected > this.minNum) this.selected--;
            },
            increase() {
                if(this.selected < this.maxNum) this.selected++;
            }
        },
        watch: {
            selected() {
                this.$emit('update:modelValue', this.selected);
            },
            modelValue() {
                this.selected = this.modelValue;
            },
            min() {
                this.minNum = this.min;
            },
            max() {
                this.maxNum = this.max;
            }
        }
    }
</script>

<style lang="scss" scoped>
.quantitySelector {
    display: grid;
    grid-template-columns: 40px 50px 40px;
    border: 1px solid $borderColor;
    border-radius: $borderRadius;
    background: $bgSecondary;
    .number {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    button {
        border: 0;
        background: transparent;
        font-size: 10px;
        &:first-child {
            border-right: 1px solid $borderColor;
        }
        &:last-child {
            border-left: 1px solid $borderColor;
        }
    }
}
</style>