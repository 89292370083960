<template>
    <div>
        <button class="default medium round _icon" @click="showCalls = true">Calls<i class="fa-solid fa-phone"></i></button>

        <ModalSimple v-model="showCalls" size="large" :loading="isLoading(['getCalls'])" title="Calls" confirm="Understood" @confirm="() => { showCalls = false; }">
            <div class="modalContentHolder">
                <DatePicker2 v-model="selectedDate" position="left"/>

                
                <div class="list" v-if="calls.calls && calls.calls.length > 0">
                    <div class="item" :key="item.ext" v-for="item of calls.calls">
                        <div class="name">
                            <div class="username">
                                <router-link :to="`/app/profile/${usersByExtension[item.ext].username}`" v-if="usersByExtension[item.ext]">{{ usersByExtension[item.ext].name }}</router-link>
                                <span v-else>Unknown</span>
                            </div>
                            <div class="ext">{{ item.ext }}</div>
                        </div>
                        <div class="bar"><div class="line" :style="`width: ${item.calls * 100 / calls.maxCalls}%`"><div class="num">{{ item.calls }}</div></div></div>
                    </div>
                </div>

                <div class="list" v-else>
                    <div class="noCallsMessage">No calls found for selected date</div>
                </div>
                
            </div>
        </ModalSimple>

    </div>
</template>

<script>

import moment from 'moment'
import { mapGetters } from 'vuex';

    export default {
        components: {

        },
        data() {
            return {
                showCalls: false,
                selectedDate: null,
                calls: []
            }
        },
        computed: {
            ...mapGetters(['users']),
            usersByExtension() {
                let users = {};
                for(const user of this.users) {
                    if(user.briaExtension) {
                        users[user.briaExtension] = {
                            name: user.name,
                            username: user.username
                        }
                    }
                }
                return users;
            }
        },
        methods: {
            getCalls() {
                if(!this.showCalls || !this.selectedDate) return;
                let date = moment.utc(this.selectedDate, "MM/DD/YYYY").format("YYYY-MM-DD");
                this.ajax("getCalls", {
                    url: '/calls/' + date,
                    method: 'GET'
                }, (err, body) => {
                    this.calls = body;
                });
            }
        },
        watch: {
            showCalls() {
                if(this.showCalls == true) this.getCalls();
            },
            selectedDate() {
                this.getCalls();
            }
        }
    }
</script>

<style lang="scss" scoped>
.modalContentHolder {
    display: grid;
    row-gap: 20px;
}

.noCallsMessage {
    padding: 20px;
    text-align: center;
}

.list {
    border-radius: $borderRadius;
    background: $bgSecondary;
    border: 1px solid $borderColor;
    padding: 5px;
}

.item {
    display: grid;
    grid-template-columns: 150px minmax(0, 1fr);
    height: 44px;
    align-items: center;
    padding: 0 15px;
    border-radius: $borderRadius;
    column-gap: 20px;
    &:nth-child(2n) {
        background: $buttonColor2;
    }
    .name {
        border-right: 1px solid $borderColor;
        // font-weight: bold;
        display: flex;
        justify-content: space-between;
        align-items: center;
        row-gap: 5px;
        padding-right: 10px;
        .ext {
            color: $text;
        }
    }
    .bar {
        .line {
            width: 0%;
            height: 9px;
            border-radius: 3px;
            background: $buttonColor;
            position: relative;
            transition: all 0.5s;
            .num {
                position: absolute;
                top: 50%;
                left: 100%;
                transform: translate(5px, -50%);
                font-size: 12px;
            }
        }
        padding-right: 30px;
    }
}
</style>