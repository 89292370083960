<template>
    <div class="dispatchStats">
        
        <div class="title">
            <h1>Overview</h1>
            <div class="titleBtns">
                <CallsStats/>
                <FullStats :stats="stats"/>
            </div>
        </div>
        <div class="_content">

            <div class="statsGroup">
                <div class="_title"><i class="fas fa-dollar-sign"></i><span>Gross income</span></div>
                <div class="_value" v-if="stats && stats.gross">${{ priceConverter(stats.gross) }}</div>
                <div class="_value" v-else>$0.00</div>
            </div>

            <div class="statsGroup">
                <div class="_title"><i class="fas fa-boxes"></i><span>Number of loads</span></div>
                <div class="_value">{{ stats && stats.numOfLoads ? stats.numOfLoads : 0 }}</div>
            </div>
            
            <ModalSimple v-model="targetModal" title="Set the target" confirm="Set" @confirm="setTarget">
                <div>
                    <Input type="text" placeholder="Set target" :error="targetErrors.value" name="New target" v-model="newTarget"/>
                </div>
            </ModalSimple>

            <div class="statsGroup">
                <div class="targetSet">
                    <div class="_title"><i class="fas fa-flag"></i><span>Target</span></div>
                    <button class="default small reverse newTargetBtn" v-if="isUserPermitted('loads', 'target')" @click="showTargetModal"><i class="fas fa-plus"></i></button>
                </div>

                <div class="_value _small" v-if="target != null && target != 0">

                        <div class="targetGroup">
                            <div class="targetInfo">
                                <div class="gross">{{ grossToTarget }}%</div>
                                <div class="target">${{ priceConverter(target) }}</div>
                            </div>
                            <div class="targetBar">
                                <div class="completionBar" :style="`width: ${grossToTarget}%`"></div>
                            </div>
                        </div>
                        
                        <div class="_subtleVal" v-if="(target - weeklyGross) > 0">${{ priceConverter((target - weeklyGross).toFixed(2)) }} left to go</div>
                        <div class="_subtleVal" v-else>Congrats! You smashed it</div>
                    
                </div>
                <div class="_value _small" v-else>Target is not set</div>

            </div>

            <div class="statsGroup" v-if="stats && stats.dispatchLeader && dispatchLeader">
                <div class="_title"><i class="fas fa-star"></i><span>Dispatch Leader</span></div>
                <div class="_value _small">
                    <div>{{ dispatchLeader.name }}</div>
                    <div class="_subtleVal">booked {{ stats.dispatchLeader.count }} {{ stats.dispatchLeader.count == 1 ? 'load' : 'loads' }} </div>
                </div>
            </div>

            <div class="statsGroup" v-if="grossLeader">
                <div class="_title"><i class="fas fa-dollar-sign"></i><span>Gross Leader</span></div>
                <div class="_value _small">
                    <div>{{ grossLeader.name }}</div>
                    <div class="_subtleVal">${{ priceConverter(stats.grossLeader.gross.toFixed(2)) }} in gross</div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FullStats from '../../components/dispatch/FullStats.vue'
import CallsStats from '../../components/dispatch/CallsStats.vue'


    export default {
        props: ['stats', 'target', 'yearWeek'],
        components: {
            FullStats,
            CallsStats
        },
        data() {
            return {
                targetModal: false,
                newTarget: this.target || '',
                targetErrors: {}
            }
        },
        methods: {
            setTarget() {
                this.targetErrors = {};

                this.$axios.post('/dispatch/target', {
                    yearWeek: this.yearWeek,
                    value: this.newTarget
                })
                .then(data => {
                    if(data.data.success) {
                        this.targetModal = false;
                    }
                })
                .catch(err => {

                    let response = err.response;

                    if(response && response.data) {
                        let body = response.data;
                        if(body.errors) this.targetErrors = body.errors;
                        else if(body.message) alert(body.message);
                        else alert("Something went wrong. Please, try again!");
                    } else {
                        alert("Something went wrong. Please, try again!");
                    }

                });
                
            },
            showTargetModal() {
                this.targetModal = true;
            }
        },
        watch: {
            target() {
                this.newTarget = this.target || '';
            }
        },
        computed: {
            ...mapGetters(['users']),
            weeklyGross() {
                return this.stats && this.stats.gross ? this.stats.gross : 0;
            },
            grossToTarget() {
                if(!this.target) return 0;
                if(this.target <= 0) return 0;
                return ((this.weeklyGross * 100) / this.target).toFixed(2);
            },
            objUsers() {
                let users = {}
                for(const item of this.users) {
                    users[item._id] = item;
                }
                return users;
            },
            dispatchLeader() {
                if(!this.stats.dispatchLeader) return null;
                return this.objUsers[this.stats.dispatchLeader.dispatcher];
            },
            grossLeader() {
                if(!this.stats) return null;
                if(!this.stats.grossLeader) return null;
                return this.objUsers[this.stats.grossLeader.dispatcher];
            }
        }
    }
</script>

<style lang="scss" scoped>
.dispatchStats {
    display: grid;
    row-gap: 30px;
    .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.titleBtns {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 20px;
}

._content {
    display: grid;
    column-gap: 30px;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    align-content: start;
}


.targetSet {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.targetGroup {
    display: grid;
    row-gap: 5px;
}

.targetInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
}

.targetBar {
    width: 100%;
    height: 10px;
    position: relative;
    border-radius: 3px;
    overflow: hidden;
    background: #eee;
    .completionBar {
        position: absolute;
        top: 0;
        left: 0;
        width: 33%;
        height: 100%;
        border-radius: 3px;
        background: $buttonColor;
    }
}

.newTargetBtn {
    padding: 0;
    width: 28px;
    height: 28px !important;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 10px;
}
</style>