<template>
    <div class="truckUpdates" v-click-outside="hideWindow" :key="yearWeek">

        <ModalSimple v-model="editTrucksModal" title="Team Update" confirm="Save" @confirm="saveTeamUpdates()">
            <TeamTruckUpdates :key="editTrucksModal" :loadedUpdates="getTeamUpdates" :yearWeek="yearWeek"/>
        </ModalSimple>


        <button class="default simple _icon" v-if="isUserPermitted('loads', 'view')" @click="switchShow">Updates<i class="fas fa-info"></i></button>

        <div class="truckUpdatesWindow" :class="{ 'active' : show }">
            <div class="_title">
                <h3>Updates</h3>
                <button v-if="user.team" @click="editTrucksModal = true" class="default small round reverse"><i class="fas fa-pencil-alt"></i></button>
            </div>


            <div class="sections">
                <button class="default small round" :class="{ 'reverse' : selectedSection != 'All' }" @click="selectedSection = 'All'">All</button>
                <button class="default small round" :class="{ 'reverse' : selectedSection != key }" @click="selectedSection = key" :key="key" v-for="(item, key) of fullUpdate">{{ item.teamDetails.name }}</button>
            </div>

            <div class="contentGroup">
                <AllTruckUpdates :loadedUpdates="selectedUpdate" :yearWeek="yearWeek"/>
            </div>

        </div>

    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TeamTruckUpdates from '../dispatch/TeamTruckUpdates.vue'
import AllTruckUpdates from '../dispatch/AllTruckUpdates.vue'

    export default {
        props: ['yearWeek'],
        components: {
            TeamTruckUpdates,
            AllTruckUpdates
        },
        data() {
            return {
                show: false,
                updates: {},
                selectedSection: 'All',
                fullUpdate: [],
                editTrucksModal: false
            }
        },
        computed: {
            ...mapGetters(['user', 'webSocket']),
            selectedUpdate() {
                if(this.fullUpdate.length <= 0) return {};

                if(this.selectedSection == 'All') {
                    let update = {};
                    for(const item of this.fullUpdate) {
                        update = {...update, ...item.update}
                    }
                    return update;
                }

                return this.fullUpdate[this.selectedSection].update;
            },
            getTeamUpdates() {
                if(!this.user.team) return {};
                let update = JSON.parse(JSON.stringify(this.fullUpdate));
                for(const item of update) {
                    if(item.team == this.user.team._id) return item.update;
                }
                return {};
            }
        },
        methods: {
            saveTeamUpdates() {
                this.editTrucksModal = false;

                if(!this.user.team) return;
                this.$axios.post('/updates', {
                    yearWeek: this.yearWeek,
                    team: this.user.team._id,
                    update: this.getTeamUpdates
                })
                .then(data => {
                    console.log(data.data);
                })
                .catch(err => {
                    alert("Something went wrong");
                });

            },
            switchShow() {
                this.show = !this.show;
            },
            hideWindow() {
                this.show = false;
            },
            getUpdates() {
                this.$axios.get(`/updates/${this.yearWeek}`)
                .then(data => {
                    this.writeUpdates(data.data); 
                });
            },
            writeUpdates(data) {
                this.fullUpdate = data;           

                let updates = {};
                for(const item of data) {
                    updates = {...updates, ...item.update}
                }
                this.updates = updates;
            },
            webSocketListeners() {
                if(!this.webSocket) return;
                this.webSocket.on('truckUpdates', (body) => {
                    if(this.yearWeek == body.yearWeek) {
                        this.writeUpdates(body.updates);
                    }
                });
            }
        },
        mounted() {
            this.webSocketListeners();
        },
        watch: {
            yearWeek() {
                this.selectedSection = 'All';
                this.getUpdates();
            },
            webSocket() {
                this.webSocketListeners();
            }
        }
    }
</script>

<style lang="scss" scoped>
.truckUpdates {
    position: relative;
    z-index: 999;
}

._title {
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    border-bottom: 1px solid $borderColor;
}

.sections {
    display: flex;
    padding: 0 20px;
    align-items: center;
    border-bottom: 1px solid $borderColor;
    button {
        margin-right: 10px;
    }
}

.truckUpdatesWindow {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 50px);
    background: $bgSecondary;
    box-shadow: $boxShadow;
    border: 1px solid $borderColor;
    border-radius: $borderRadius;
    width: 650px;
    height: 500px;
    display: grid;
    grid-template-rows: 60px 60px minmax(0, 1fr);
    opacity: 0;
    pointer-events: none;
    transition: ease 0.5s;
    &.active {
        opacity: 1;
        pointer-events: auto;
        transform: translate(-50%, 20px);
    }
    .contentGroup {
        width: 100%;
        overflow: auto;
    }
}
</style>