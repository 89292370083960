<template>
    <div>
        <button class="default medium round _icon" @click="showStatistics = true" :disabled="stats ? false : true">Full Stats <i class="fas fa-chart-pie"></i></button>

        <ModalSimple v-model="showStatistics" size="large" title="Statistics" confirm="Understood" @confirm="() => { showStatistics = false; }">
            <div class="modalContentHolder" v-if="stats">
                <div class="sections">
                    <button class="default small round" @click="selectedSection = key" :class="{ 'reverse' : selectedSection != key }" :key="key" v-for="(item, key) of sections">{{ item.name }}</button>
                </div>

                <div class="contentGroup">
                    <component v-bind:is="sections[selectedSection].component" :stats="stats"></component>
                </div>
            </div>
        </ModalSimple>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CarriersStats from './stats/CarriersStats.vue'
import UsersStats from './stats/UsersStats.vue'
import TeamsStats from './stats/TeamsStats.vue'

    export default {
        props: ['stats'],
        components: {
            CarriersStats,
            UsersStats,
            TeamsStats
        },
        data() {
            return {
                sections: [
                    { name: 'Carriers', component: 'CarriersStats' },
                    { name: 'Users', component: 'UsersStats' },
                    { name: 'Teams', component: 'TeamsStats' }
                ],
                selectedSection: 0,
                showStatistics: false
            }
        }
    }
</script>

<style lang="scss" scoped>

.sections {
    display: flex;
    align-items: center;
    button {
        margin-right: 10px;
    }
}

.modalContentHolder {
    display: grid;
    row-gap: 30px;
}

</style>