<template>
    <div class="listOfUpdates" v-if="Object.keys(availableTrucks).length > 0">
        <div :key="key" v-for="(item, key) of availableTrucks" class="item">

            <div class="image">
                <div class="img" v-if="listOfCarriers[key]">{{ getCarrierAvatar(listOfCarriers[key]) }}</div>
            </div>

            <div class="contentGroup">
                <div class="_name" v-if="listOfCarriers[key]">{{ listOfCarriers[key].name }} - #{{ listOfCarriers[key].mcNumber }}</div>
                
                <div class="truckItem" :key="key" v-for="(item, key) of item">
                    <div class="value">{{ numOfAvailableTrucks(item) }} {{ numOfAvailableTrucks(item) == 1 ? 'truck' : 'trucks' }} {{ item.location }}</div>
                    <div class="note" v-if="item.note"><i class="fas fa-star"></i>{{ item.note }}</div>
                    <div class="note" v-if="item.driver && item.driver._id && item.numOfAll === 1"><i class="fa-regular fa-id-card"></i>{{ item.driver.name }} {{ item.driver.lastname }}</div>
                </div>
            </div>

        </div>
    </div>
    <div class="message" v-else>
        <div class="icon"><i class="fas fa-check"></i></div>
        <div class="msg">All trucks are covered</div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CarrierTruckUpdate from './CarrierTruckUpdate.vue'

    export default {
        props: ['yearWeek', 'loadedUpdates'],
        components: {
            CarrierTruckUpdate
        },
        data() {
            return {
                updates: this.loadedUpdates || {}
            }
        },
        computed: {
            ...mapGetters(['carriers', 'user']),
            listOfCarriers() {
                let result = {};
                for(const carrier of this.carriers) {
                    result[carrier._id] = result[carrier._id] || carrier;
                }
                return result;
            },
            availableTrucks() {
                let updates = {};
                for(const item in this.updates) {
                    if(this.updates[item]) {
                        for(const row of this.updates[item]) {
                            let num = row.numOfAll - row.numOfCovered;
                            if(num > 0) {
                                updates[item] = updates[item] || [];
                                updates[item].push(row);
                            }
                        }
                    }
                }
                return updates;
            }
        },
        methods: {
            numOfAvailableTrucks(item) {
                return Number(item.numOfAll) - Number(item.numOfCovered);
            },
            handleSubmit() {
                this.$axios.post('/updates', {
                    yearWeek: this.yearWeek,
                    team: this.user.team._id,
                    update: this.updates
                })
                .then(data => {
                    console.log(data.data);
                })
                .catch(err => {
                    alert("Something went wrong");
                });
            },
            getCarrierAvatar(carrier) {
                return carrier.name[0] + carrier.name[1];
            }
        },
        watch: {
            loadedUpdates: {
                deep: true,
                handler() {
                    this.updates = this.loadedUpdates;
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.listOfUpdates {
    display: grid;
}

.name {
    display: grid;
    grid-template-columns: 30px minmax(0, 1fr);
    align-items: center;
}

.item {
    display: grid;
    grid-template-columns: 50px minmax(0, 1fr);
    align-items: start;
    align-content: start;
    column-gap: 20px;
    row-gap: 10px;
    padding: 10px;
    border-bottom: 1px solid $borderColor;
    .contentGroup {
        display: grid;
        row-gap: 10px;
        ._name {
            font-size: 14px;
            font-weight: 500;
            text-transform: uppercase;
        }
    }
}

.image {
    width: 100%;
    padding-top: 100%;
    position: relative;
    .img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $buttonColor2;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        color: $buttonColor;
        font-weight: bold;
    }
}

.truckItem {
    display: grid;
    row-gap: 5px;
    .value {
        
    }
    i {
        margin-right: 5px;
        font-size: 8px;
    }
    .note {
        margin-left: 15px;
        color: $buttonColor;
        display: grid;
        grid-template-columns: auto minmax(0, 1fr);
        column-gap: 5px;
    }
}

.message {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .icon {
        font-size: 50px;
        margin-bottom: 10px;
    }
}
</style>