export default {
    data() {
        return {
            _incompleteLoads: [],
            _showIncompleteLoads: false,
            _incompleteLoadsTimeout: null
        }
    },
    watch: {
        _incompleteLoadsCount() {
            if(this._incompleteLoadsCount === 0) {
                this._showIncompleteLoads = false;
            }
        }
    },
    computed: {
        _incompleteLoadsCount() {
            return this._incompleteLoads.length;
        }
    },
    methods: {
        async _incomplete_loads_get() {
            await this.ajax('incomplete_loads_getListOfIncompleteLoads', {
                method: 'GET',
                url: `/dispatch/getIncompleteLoads`,
            }, (err, body) => {
                if(!err) this._incompleteLoads = body;
            });

            clearTimeout(this._incompleteLoadsTimeout);
            this._incompleteLoadsTimeout = setTimeout(() => {
                this._incomplete_loads_get();
            }, 1000 * 60 * 5);
        }
    },
    unmounted() {
        clearTimeout(this._incompleteLoadsTimeout);
    },
    mounted() {
        this._incomplete_loads_get();
    }
}