<template>
    <div class="load">
        <div class="header-content">
            <div>#{{ load.loadID }}</div>
            <div>{{ pickupDate }}</div>
            <div>{{ load.origin }}</div>
            <div>{{ load.destination }}</div>
            <div style="text-align: right;">${{ priceConverter(load.rate.toFixed(0)) }}</div>
        </div>
        <div class="other-info">
            <span v-if="load.carrier && load.carrier.name">Carrier: <a href="" @click.prevent>{{load.carrier.name}}</a>&nbsp; &nbsp;</span>
            <span v-if="load.broker">Broker: <a href="" @click.prevent>{{load.broker}}</a></span>
        </div>
        <div class="action">
            <button class="default small round" v-if="load.PODs.length <= 0" @click="uploadPOD">Upload POD</button>
            <button class="default small round" v-if="load.status !== 'delivered'" @click="markAsDelivered">Mark as delivered</button>
            <button class="default small round" v-if="load.status === 'delivered' && load.PODs.length > 0" @click="markAsCompleted">Mark as completed</button>

        </div>
    </div>
</template>

<script>
import moment from 'moment'
import FilePicker from '../../../mixins/FilePicker';

    export default {
        mixins: [FilePicker],
        props: ['load'],
        computed: {
            pickupDate() {
                return moment.utc(this.load.pickupDate).format("DD/MM")
            }
        },
        methods: {
            uploadPOD() {
                this.createFilePicker(null, (file) => {
                    var formData = new FormData();
                    formData.append("file", file);

                    this.ajax('incompleteLoads_UploadPOD', {
                        method: 'POST',
                        url: `/dispatch/load/${this.load._id}/uploadDocument/POD`,
                        data: formData,
                    }, (err, body) => {
                        if(!err) {
                            this.$emit('podUploaded');
                        } else {
                            alert(body.message || 'Something went wrong. Please, try again!')
                        }
                    });

                });
            },
            markAsDelivered() {
                this.ajax('incompleteLoads_MarkAsDelivered', {
                    method: 'POST',
                    url: `/dispatch/loadStatus/${this.load._id}/delivered`
                }, (err, body) => {
                    if(!err) {
                        this.$emit('statusChanged');
                    } else {
                        alert(body.message || 'Something went wrong. Please, try again!')
                    }
                });
            },
            markAsCompleted() {
                this.ajax('incompleteLoads_MarkAsDelivered', {
                    method: 'POST',
                    url: `/dispatch/completeLoad/${this.load._id}`
                }, (err, body) => {
                    if(!err) {
                        this.$emit('statusChanged');
                    } else {
                        alert(body.message || 'Something went wrong. Please, try again!')
                    }
                });
            }
        },
    }
</script>

<style lang="scss" scoped>
.load {
    border: 2px dashed $borderColor;
    padding: 20px;
    border-radius: 12px;
    display: grid;
    gap: 10px;
    background: $bgSecondary;
}

.header-content {
    display: grid;
    grid-template-columns: 75px 100px minmax(100px, 1fr) minmax(100px, 1fr) 100px;
    align-items: center;
}
.other-info {
    border-top: 2px dashed $borderColor;
    padding-top: 10px;
}

.action {
    display: flex;
    gap: 15px;
    align-items: center;
}
</style>